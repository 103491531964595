const zonesMap: { [key: string]: string } = {
    EU: 'Europe (Western, Central, Eastern Europe)',
    WEU: 'Western European Union',
    CEEN: 'Central and Eastern Europe',
    LATAM: 'Latin America',
    Africa: 'Africa',
    Asia: 'Asia',
};

export default zonesMap;
