interface ErrorReport {
    date: string;
    region: string,
    kind_app: string;
    traffic_distribute_mode: string;
    country_code: string;
    rq_bundle: string;
    v_provider: string;
    is_200: boolean;
    user_category: string;
    zone: string;
    user_ip: number;
    tmax: number;
    purposes_consent: number;
    purposes_li_consent: number;
    vendors_consent: number[];
    vendors_li_consent: number[];
    total_errors: number;
    user_consent: number;
    unexpected_status: number;
    client_err_ctx_deadline: number;
    client_err_ctx_cancelled: number;
    client_err_dial_tcp_timeout: number;
    client_err_other: number;
    failed_read_body: number;
    reading_response_body: number;
    url_blacklisted: number;
    empty_response: number;
    no_content: number;
    ad_blocked: number;
    duplicate_of_creative: number;
    blocked_app: number;
    no_consent: number;
    empty_oaid_gaid: number;
    high_bidfloor: number;
    unsupported_template: number;
    unsupported_app: number;
    no_images_or_featured_media: number;
    unsupported_country: number;
    langs_not_equal: number;
    empty_market_url: number;
    no_gallery_link: number;
    no_ip: number;
    oaid_request: number;
    no_settings: number;
    empty_imp_object: number;
    empty_device_object: number;
    undefined_country: number;
    incorrect_assets: number;
    inactive_country: number;
    unsupported_split_key: number;
    request_validation_failed: number;
    template_unsupported_by_bundle: number;
    unknown_errors: number;
    unsupported_winteraction_type: number;
    unknown_imp_id: number;
    deduplication_err: number;
    bad_image_url: number;
}

interface ErrorReportStats {
    stats: ErrorReport[];
}

interface FetchErrorReportRequest {
    from: string;
    to: string;
    region?: string;
    country_code?: string;
    v_provider?: string;
    rq_bundle?: string;
    kind_app?: string;
    is_200?: boolean;
    traffic_distribute_mode?: string;
    is_general_provider: boolean;
    user_category?: string;
    user_consent?: string;
    zone?: string;
    user_ip?: string;
    template_type?: string;
    groupBy: string[];
    purposes_consent?: string[];
    purposes_li_consent?: string[];
    vendors_consent?: string[];
    vendors_li_consent?: string[];
}

enum UserConsent {
    WithoutUserConsent = 'No',
    WithUserConsent = 'Yes',
}

export const UserConsentMap: Record<string, string> = {
    0: UserConsent.WithoutUserConsent,
    1: UserConsent.WithUserConsent,
};

export type {
    ErrorReport,
    ErrorReportStats,
    FetchErrorReportRequest,
    UserConsent,
};
